<template>
  <AuthWrapper>
    <p class="auth-notice">
      {{ $t("auth.already_have_account") }}
      <router-link to="/auth/login">{{ $t("auth.sign_in") }}</router-link>
    </p>
    <div class="auth-contents">
      <a-form
        name="register"
        :model="formState"
        @finish="handleSubmit"
        layout="vertical"
        style="width: 100%; padding-top: 16px;"
      >
        <sdHeading as="h3" class="text-center">
          {{ $t("auth.sign_up_to") }} {{ $t("brokvisor") }}</sdHeading
        >
        <a-row gutter="4">
          <a-col :xs="24" :md="12">
            <a-form-item label="Name" name="name" :rules="[{ required: true }]">
              <a-input v-model:value="formState.name" placeholder="Name" />
        </a-form-item>
      </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item label="Last Name" name="lastname" :rules="[{ required: true }]">
              <a-input v-model:value="formState.lastname" placeholder="Last Name" />
        </a-form-item>
      </a-col>
        </a-row>
        <a-form-item name="email" label="Email Address" :rules="[{ required: true }]">
          <a-input
            type="email"
            v-model:value="formState.email"
            placeholder="name@example.com"
          />
        </a-form-item>
        <a-form-item name="phone" label="Phone Number" :rules="[{ required: true }]">
          <a-input
            type="text"
            v-model:value="formState.phone"
            placeholder="Phone number"
          />
        </a-form-item>
        <a-form-item name="address" label="Address" :rules="[{ required: true }]">
          <a-input
            type="text"
            v-model:value="formState.address"
            placeholder="Address"
          />
        </a-form-item>
        <a-row gutter="4">
          <a-col :xs="24" :md="8">
            <a-form-item name="city" label="City" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.city"
                placeholder="City"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="8">
            <a-form-item name="state" label="State" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.state"
                placeholder="State"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="8">
            <a-form-item name="zip_code" label="Zip Code" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.zip_code"
                placeholder="Zip code"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item name="invoice_address" label="Invoice address" :rules="[{ required: true }]">
          <a-input
            type="text"
            v-model:value="formState.invoice_address"
            placeholder="Invoice address"
          />
        </a-form-item>
        <a-form-item name="vat_number" label="VAT number" :rules="[{ required: true }]">
          <a-input
            type="text"
            v-model:value="formState.vat_number"
            placeholder="VAT number"
          />
        </a-form-item>
        <a-row gutter="4">
          <a-col :xs="24" :md="8">
            <a-form-item name="invoice_city" label="Invoice city" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.invoice_city"
                placeholder="Invoice city"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="8">
            <a-form-item name="invoice_country" label="Invoice country" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.invoice_country"
                placeholder="Invoice country"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="8">
            <a-form-item name="invoice_zip" label="Invoice zip" :rules="[{ required: true }]">
              <a-input
                type="text"
                v-model:value="formState.invoice_zip"
                placeholder="Invoice zip"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row gutter="4">
          <a-col :xs="24" :md="12">
            <a-form-item label="Password" name="password" :rules="[{ required: true }]">
          <a-input-password
            type="password"
            v-model:value="formState.password"
            placeholder="Password"
          />
        </a-form-item>
      </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item label="Confirm password" name="confirm_password" :rules="[{ required: true, validator: validateConfirmPassword }]">
              <a-input-password
                type="password"
                v-model:value="formState.confirm_password"
                placeholder="Confirm password"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="auth-form-action">
          <a-checkbox @change="onChange">
            Creating an account means you’re okay with our Terms of Service and
            Privacy Policy
          </a-checkbox>
        </div>
        <a-form-item>
          <sdButton
            class="btn-create element-center"
            htmlType="submit"
            type="primary"
            size="large"
          >
            Create Account
          </sdButton>
        </a-form-item>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script setup>
import { AuthWrapper } from "./style";
import { reactive, ref } from "vue";

const values = ref(null);
const checked = ref(null);
const handleSubmit = (value) => {
  values.value = value;
};

const onChange = (check) => {
  checked.value = check;
};
const formState = reactive({
  name: "",
  lastname: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  invoice_address: "",
  vat_number: "",
  invoice_city: "",
  invoice_country: "",
  invoice_zip: "",
  password: "",
  confirm_password: ""
});

const validateConfirmPassword = (_, value, callback) => {
  if (value && value !== formState.password) {
    callback(new Error('The passwords do not match!'));
  } else {
    callback();
  }
};
</script>
